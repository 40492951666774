import { graphql, PageProps } from "gatsby"
import { flatten, uniq } from "lodash"
import React, { FunctionComponent, useCallback, useState } from "react"
import { ValueType } from "react-select"
import { useWindowSize } from "react-use"
import {
  Filter,
  isSingleValidValue,
  SimpleOption,
} from "../components/common/filters"
import SEO from "../components/common/seo"
import Layout from "../components/layout/layout"
import NewsCard from "../components/news/card"
import Style, {
  newsDropdownStyle,
} from "../components/news/inTheNews/page.style"
import { COLORS } from "../styles"
import { Article } from "../templates/news/news"

interface Data {
  contentfulNewsPage: {
    seoTitle: string
    seoDescription: string
    slug: string
  }
  allContentfulNews: {
    edges: {
      node: Article
    }[]
  }
}

const getBaseNumberOfDisplayedArticles = (windowWidth: number): number => {
  if (windowWidth > 1659) {
    return 8
  }
  return 6
}

const NewsPage: FunctionComponent<PageProps<
  Data,
  { currentFilter: string }
>> = ({ location, data }) => {
  const { width: windowWidth } = useWindowSize()
  const [setsToDisplay, setSetsToDisplay] = useState(1)
  const [currentFilter, setCurrentFilter] = useState<string | null>(
    location.state?.currentFilter || null
  )

  const getArticlesToDisplay = useCallback(() => {
    const filteredArticles = data.allContentfulNews.edges
      .map(edge => edge.node)
      .filter(
        article =>
          currentFilter === null ||
          article.categories
            ?.map(category => category.name)
            .includes(currentFilter)
      )
    const filteredAndSlicedArticles = filteredArticles.slice(
      0,
      getBaseNumberOfDisplayedArticles(windowWidth) * setsToDisplay
    )
    const areAllArticlesDisplayed =
      filteredArticles.length === filteredAndSlicedArticles.length
    return { filteredAndSlicedArticles, areAllArticlesDisplayed }
  }, [data.allContentfulNews, windowWidth, setsToDisplay, currentFilter])

  const getCategoryOptions = useCallback(() => {
    const articles = data.allContentfulNews.edges.map(edge => edge.node)
    const categories = uniq(
      flatten(articles.map(article => article.categories))
        .filter(category => !!category)
        .map(category => category!.name)
    )
    const options = categories.map(category => ({
      label: category,
      value: category,
    }))
    return [{ label: "All", value: null }, ...options]
  }, [data.allContentfulNews])

  const handleFilter = useCallback(
    (value: ValueType<SimpleOption>) => {
      if (isSingleValidValue(value)) {
        setCurrentFilter(value.value)
      }
    },
    [setCurrentFilter]
  )

  return (
    <Layout>
      <SEO
        title={data.contentfulNewsPage.seoTitle}
        description={data.contentfulNewsPage.seoDescription}
      />
      <Filter
        backgroundColor={COLORS.PALE_GREY}
        title="In The News"
        currentValue={currentFilter}
        options={getCategoryOptions()}
        onChange={handleFilter}
        placeholder="Filter By Category"
        styles={newsDropdownStyle}
      />
      <Style.NewsGrid>
        {getArticlesToDisplay().filteredAndSlicedArticles.map(article => (
          <NewsCard
            key={article.id}
            article={article}
            state={{ currentFilter }}
          />
        ))}
        {!getArticlesToDisplay().areAllArticlesDisplayed && (
          <Style.SeeMoreButton
            icon="rightPlus"
            mode="button"
            onClick={() => setSetsToDisplay(currentNumber => currentNumber + 1)}
          >
            See More
          </Style.SeeMoreButton>
        )}
      </Style.NewsGrid>
    </Layout>
  )
}
export const query = graphql`
  query contentfulNewsPage {
    contentfulNewsPage {
      seoTitle
      seoDescription
      slug
    }
    allContentfulNews(
      sort: { order: DESC, fields: date }
      filter: { date: { ne: null } }
    ) {
      edges {
        node {
          date(formatString: "MMMM D, YYYY")
          externalLink
          id
          image {
            id
            fluid(quality: 90, maxWidth: 2000) {
              ...GatsbyContentfulFluid
            }
          }
          title
          slug
          author
          content {
            content
          }
          categories {
            name
          }
        }
      }
    }
  }
`

export default NewsPage
