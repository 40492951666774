import { graphql, useStaticQuery } from "gatsby"
import React, { FunctionComponent } from "react"
import { COLORS } from "../../../styles"
import { Article } from "../../../templates/news/news"
import Styles from "./card.style"

interface Props {
  article: Article
  state?: any
}

interface NewsCardQuery {
  allContentfulNewsPage: {
    edges: {
      node: {
        slug: string
      }
    }[]
  }
}

const newsCardsQuery = graphql`
  query NewsCardsQuery {
    allContentfulNewsPage {
      edges {
        node {
          slug
        }
      }
    }
  }
`

const NewsCard: FunctionComponent<Props> = ({ article, state }) => {
  const {
    allContentfulNewsPage: { edges },
  } = useStaticQuery<NewsCardQuery>(newsCardsQuery)
  const newsSlug = edges[0].node.slug
  return (
    <Styles.Container>
      <Styles.ImageContainer>
        <Styles.Image fluid={article.image.fluid} />
        <Styles.Date $textColor="white">{article.date}</Styles.Date>
      </Styles.ImageContainer>
      <Styles.ContentContainer>
        <Styles.Title>{article.title}</Styles.Title>
        {article.author && <Styles.Author>BY {article.author}</Styles.Author>}
        <Styles.Content $textColor={COLORS.CHARCOAL}>
          {article.content.content}
        </Styles.Content>
        <Styles.Link
          to={article.externalLink || `/${newsSlug}/${article.slug}`}
          state={state}
        >
          Read More
        </Styles.Link>
      </Styles.ContentContainer>
    </Styles.Container>
  )
}

export default NewsCard
