import Img from "gatsby-image"
import styled from "styled-components"
import { COLORS, IPHONE5_MAX_WIDTH, MOBILE_MAX_WIDTH } from "../../../styles"
import { CTA } from "../../common/cta"
import { H5, S1, T1 } from "../../common/text"

export default {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    max-width: 370px;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      width: 315px;
      margin: 0 auto;
    }
    @media screen and (max-width: ${IPHONE5_MAX_WIDTH}px) {
      width: 275px;
    }
  `,
  ImageContainer: styled.div`
    position: relative;
    width: 100%;
    height: 250px;
    margin-bottom: 15px;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      height: 215px;
    }
  `,
  ContentContainer: styled.div`
    max-width: 340px;
  `,
  Image: styled(Img)`
    width: 100%;
    height: 100%;
  `,
  Date: styled(S1)`
    position: absolute;
    left: 0px;
    bottom: 0px;
    padding: 6px 9px;
    height: 27px;
    background-color: ${COLORS.BOTTLE_GREEN};
    margin-bottom: 0px;
  `,
  Author: styled(S1)`
    margin-bottom: 10px;
  `,

  Title: styled(H5)`
    margin-bottom: 10px;
  `,
  Content: styled(T1)`
    margin-bottom: 20px;
  `,

  Link: styled(CTA)``,
}
