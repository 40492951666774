import { Styles } from "react-select"
import styled from "styled-components"
import { COLORS, MD_BREAKPOINT } from "../../../styles"
import Button from "../../common/button"
import { H4 } from "../../common/text"
import { dropdownStyle } from "../../explore/dropdown.style"

const Style = {
  Title: styled(H4)`
    background-color: ${COLORS.PALE_GREY};
    width: 100%;
    text-align: center;
    height: 60px;
    padding-top: 10px;
  `,
  NewsGrid: styled.div`
    margin: 0 auto;
    padding: 50px 33px;
    display: grid;
    grid-template-columns: repeat(auto-fill, 370px);
    justify-content: center;
    column-gap: 33px;
    row-gap: 50px;
    max-width: 1646px;
    @media screen and (max-width: ${MD_BREAKPOINT}px) {
      padding: 0 0 30px 0;
      grid-template-columns: repeat(auto-fill, 315px);
      row-gap: 40px;
    }
  `,
  SeeMoreButton: styled(Button)`
    grid-column: 1 / -1;
    width: 100%;
  `,
}

export default Style

export const newsDropdownStyle: Partial<Styles> = {
  ...dropdownStyle,
  option: (provided, state) => ({
    ...dropdownStyle.option?.(provided, state),
    backgroundColor: state.isSelected
      ? `${COLORS.LIGHT_BLUE_GREY}`
      : `${COLORS.WHITE}`,
  }),
}
